import { useContext, useEffect, useState } from "react";
import { MapViewStateProvider } from "../../../../contexts";
import { MapDateRangeContext } from "../../../../contexts/components/MapDateRangeContext";
import { DebugBar } from "../../../../debug/DebugBar";
import { Dates } from "../../../../utils/dates";
import { ControlBar } from "../controlBar";
import { FeedbackBar } from "../installations";
import { ShipMap } from "./ShipMap";

export const MapAndControls = () => {

    const [beamsSelected, setBeamsSelected] = useState(false);
    const [cloudSelected, setCloudSelected] = useState(false);
    const [rainSelected, setRainSelected] = useState(false);
    const [wavesSelected, setWavesSelected] = useState(false);
    const [legendSelected, setLegendSelected] = useState(false);

    const [cloudEnabled, setCloudEnabled] = useState(false);
    const [rainEnabled, setRainEnabled] = useState(false);
    const [wavesEnabled, setWavesEnabled] = useState(false);

    const {fromMillis} = useContext(MapDateRangeContext);

    useEffect(() =>{

        const daysInThePast = Dates.today().diffInDays(fromMillis);  
        
        const canShowCloud = daysInThePast <= 7;
        const canShowRain = daysInThePast <= 30;
        const canShowWaves = daysInThePast <= 7;

        setCloudEnabled(canShowCloud);
        setRainEnabled(canShowRain);
        setWavesEnabled(canShowWaves);

        if (!canShowCloud) {
            setCloudSelected(false);
        }

        if (!canShowRain) {
            setRainSelected(false);
        }

        if (!canShowWaves) {
            setWavesSelected(false);
        }

    }, [fromMillis])

    const handleOnBeamsClick = () => {
        setBeamsSelected(prev => !prev);
    }

    const handleOnCloudClick = () => {
        if (cloudEnabled){
            setCloudSelected(prev => !prev);
        }
    }

    const handleOnRainClick = () => {
        if (rainEnabled){
            setRainSelected(prev => !prev);
        }
    }

    const handleOnWavesClick = () => {
        if (wavesEnabled){
            setWavesSelected(prev => !prev);
        }
    }

    const handleOnLegendClick = () => {
        setLegendSelected(prev => !prev);
    }

    const handleOnHideLegend = () => {
        setLegendSelected(false);
    }

    return (
        <MapViewStateProvider>
            <ShipMap 
                showBeams={beamsSelected}
                showCloud={cloudSelected} 
                showRain={rainSelected} 
                showWaves={wavesSelected}
                showLegend={legendSelected}
                onHideLegend={handleOnHideLegend}
            />
            <DebugBar />
            <FeedbackBar />
            <ControlBar
                beamsSelected={beamsSelected}
                onBeamsClick={handleOnBeamsClick}
                cloudEnabled={cloudEnabled}
                cloudSelected={cloudSelected}
                onCloudClick={handleOnCloudClick}
                rainEnabled={rainEnabled}
                rainSelected={rainSelected}
                onRainClick={handleOnRainClick}
                wavesEnabled={wavesEnabled}
                wavesSelected={wavesSelected}
                onWavesClick={handleOnWavesClick}
                legendSelected={legendSelected}
                onLegendClick={handleOnLegendClick}
            />
        </MapViewStateProvider>
    )
}
