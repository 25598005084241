import React, { useContext } from "react";
import {
    BeamSelector,
    MapZoom,
    WeatherSelector,
} from "./components";

import { MapDateRange } from "../mapDateRange/MapDateRange";

import styles from "./ControlBar.module.css";
import { AuthContext } from "../../../../contexts/components/AuthContext";


const Item = ({children}:{children: React.ReactNode}) => {

    return (
        <div className={styles.item}>
            {children}
        </div>
    );
}

const ControlBar = (
    {
        beamsSelected,
        onBeamsClick,
        cloudSelected,
        cloudEnabled,
        onCloudClick,
        rainSelected,
        rainEnabled,
        onRainClick,
        wavesSelected,
        wavesEnabled,
        onWavesClick,
        legendSelected,
        onLegendClick,
    }: 
    {
        beamsSelected: boolean,
        onBeamsClick: () => void,
        cloudSelected: boolean,
        cloudEnabled: boolean,
        onCloudClick: () => void,
        rainSelected: boolean,
        rainEnabled: boolean,
        onRainClick: () => void,
        wavesSelected: boolean,
        wavesEnabled: boolean,
        onWavesClick: () => void,
        legendSelected: boolean,
        onLegendClick: () => void,
    }
) => {

    const {user} = useContext(AuthContext);

    return (
        <div className={styles.root}>
            <Item>
                <MapZoom />
            </Item>
            {user && user.canViewSatelliteBeamOverlays &&
                <Item>
                    <BeamSelector 
                        selected={beamsSelected}
                        onClick={onBeamsClick}
                    />
                </Item>
            }
            <Item>
                <WeatherSelector 
                    cloudSelected={cloudSelected}
                    rainSelected={rainSelected}
                    wavesSelected={wavesSelected}
                    legendSelected={legendSelected}
                    cloudEnabled={cloudEnabled}
                    rainEnabled={rainEnabled}
                    wavesEnabled={wavesEnabled}
                    onCloudsClick={onCloudClick}
                    onRainClick={onRainClick}
                    onWavesClick={onWavesClick}
                    onLegendClick={onLegendClick}
                />
            </Item>
            <Item>
                <MapDateRange />
            </Item>
        </div>
    )
}

export default ControlBar;