import { useContext, useMemo } from "react";
import { Layer, Source } from "react-map-gl";
import { BeamContoursContext } from "../../../../contexts/components/BeamContoursContext";

export const BeamContours = (
    {
        visible
    }:
    {
        visible: boolean
    }
) => {

    const { beamContours } = useContext(BeamContoursContext);

    const content = useMemo(() => 
        beamContours
            .sort((a, b) => {
                if (a.zIndex < b.zIndex) {
                    return -1;
                }
                if (a.zIndex > b.zIndex){
                    return 2;
                }
                return 0;
            })
            .map((beamContour, index) => {
                return (
                    <Source 
                        key={`beam-contour-${index}`} 
                        type="geojson" 
                        data={{
                            type: "Feature",
                            geometry: {
                                type: "Polygon",
                                coordinates: [
                                    beamContour.coordinates
                                ]
                            },
                            properties: {}
                        }} >
                        <Layer
                            key={`beam-fill-layer-${index}`}
                            beforeId="ship-route-layer-id"
                            type="fill"
                            paint={{
                                "fill-opacity": beamContour.fillOpacity,
                                "fill-color": beamContour.fillColour
                            }}
                            layout={{
                                visibility: visible ? "visible" : "none"
                            }}
                        />
                        <Layer 
                            key={`beam-outline-layer-${index}`}
                            beforeId="ship-route-layer-id"
                            type="line"
                            paint={{
                                "line-color": beamContour.colour,
                                "line-width": beamContour.outlineWidth,
                                "line-opacity": beamContour.opacity
                            }}
                            layout={{
                                visibility: visible ? "visible" : "none"
                            }}
                        />
                    </Source>)
            }), [beamContours, visible]);  

    return (<>{content}</>);

}