import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatellite } from "@fortawesome/free-solid-svg-icons";
import styles from "./BeamSelector.module.css";

interface ButtonProps {
    title: string,
    isSelected: boolean,
    disabled: boolean,
    onClick: () => void,
    children?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
    title, 
    isSelected, 
    disabled, 
    onClick, 
    children
}) => {
    
    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClick();
   }

   return (
        <button
            type="button"
            disabled={disabled}
            title={title}
            className={`${classnames(styles.button, isSelected && styles.selected, disabled && styles.disabled)}`}
            onClick={handleOnClick}
        >
            {children}
        </button>
    );
};

interface BeamSelectorProps {
    selected: boolean,
    onClick: () => void,
}

const BeamSelector : React.FC<BeamSelectorProps> = ({
    selected,
    onClick
}) => {

    function handleButtonClick() {
        onClick();
    }

    return(
        <div className={styles.root}>
            <Button 
                onClick={handleButtonClick}
                title="Satellite Beams"
                isSelected={selected}
                disabled={false}
            >
                <FontAwesomeIcon icon={faSatellite} />
            </Button>           
        </div>
    );
}

export default BeamSelector